import { h, createApp, onMounted } from 'vue'
import * as RDPlay from "rd-play"
import 'rd-play/dist/main.css'

const loadProject = function (data) {
   RDPlay.__tla.then(res => {
      const app = createApp({
         name: 'dataplay',
         setup: () => {
            const loading = document.querySelector('#appLoading')
            const loadingError = document.querySelector('#loadingError')
            loadingError.onclick = function () {
               location.reload()
            }
            const childVNode = h(RDPlay.RdPlay, {
               width: "100%", height: "100%", data,
               onError: () => {
                  loading.className = "error"
               }, onSuccess: () => {
                  loading.className = "hide"
               }
            })
            onMounted(() => {
               loading.className = ""
            })
            return () => childVNode
         }
      })
      app.mount("#app")
   })

}

window.loadProject = loadProject
show()