export const readFile = function (file, type = "text") {
   return new Promise((resolve, reject) => {
      if (file instanceof Blob) {
         var reader = new FileReader()
         reader.onload = function (evt) {
            resolve(evt.target.result)
         }
         reader.onerror = (event) => {
            reject(event)
         }
         if (type == 'text') {
            reader.readAsText(file)
         } else if (type == 'url') {
            reader.readAsDataURL(file)
         } else {
         }
      } else {
         reject("不是二进制文件")
      }
   })
}

let fileinput = null

export const selectFile = function (callback) {
   if (!fileinput) {
      fileinput = document.createElement("input")
      fileinput.setAttribute("type", "file")
      fileinput.style.display = "none"
      fileinput.addEventListener('change', evt => {
         callback(fileinput.files[0])
      })
   }
   fileinput.click()
}